import { useState } from "react";
import { finderSendMessage, sendAction } from "../Api/userApi";
const FinderPanel = (props) => {
  const [finder, setFinder] = useState({ email: "", code: "", consent: false });
  const [err, setErr] = useState({ email: false, code: false, consent: false });
  const [tryCount, setTryCount] = useState(0);
  const [folded, setFolded] = useState(true);

  const onChangeHandler = (e) => {
    setFinder({ ...finder, [e.target.name]: e.target.value });
  }
  const checkConsentHandler = () => {
    setFinder({ ...finder, consent: !finder.consent });
  }

  const sendHandler = async (e) => {
    e.preventDefault();
    const tmpErr = { email: false, code: false };

    if (finder.consent === false) {
      tmpErr.consent = true;
      setErr(tmpErr);
      return;
    }
    if (validateEmail(finder.email) === null) {
      tmpErr.email = true;
      setErr(tmpErr);
      return;
    }

    setFinder({ ...finder, code: finder.code.trim() });
    if (finder.code.length !== 5) {
      tmpErr.code = true;
      setErr(tmpErr);
      return;
    }
    setErr(tmpErr);

    if (tryCount < 10) {
      setTryCount((tc) => { return tc + 1 });
    }


    const res = await finderSendMessage(finder);
    setFinder({ email: "", code: "", consent: false });
    if (res === null) {
      // props.setWarning(
      //   { header:"Błąd logowania", 
      //   content:"Spróbuj późnej", 
      //   btnHandler:()=>props.setWarning(null), 
      //   btnLabel:"ok"}
      //   );
      return;
    } else if (parseInt(res.errorCode) === 1) {

      props.setWarning(
        {
          header: "Wiadomość została wysłana",
          content: "Serdecznie dziękujemy za przesłanie kontaktu, właściciel i podopieczny są Ci bardzo wdzięczni.",
          btnHandler: () => props.setWarning(null),
          btnLabel: "ok"
        }
      );
    } else if (parseInt(res.errorCode) === 4) {

      props.setWarning(
        {
          header: "Nieznany kod",
          content: "Podany kod nie występuje w naszym systemie, sprawdź czy nie wystąpiła pomyłka",
          btnHandler: () => props.setWarning(null),
          btnLabel: "ok"
        }
      );
    } else {

    }



  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  if (tryCount >= 10) {
    return "";
  }

  const findMoreHandler = () => {
    sendAction({ x: 'more' });
    window.location.href = 'czyjazguba.html';
  }

  // if(folded){
  //     return(
  //         <div className="finder-panel ">
  //             <h2>Czyja zguba?</h2>
  //             <h3>Łączymy znalazcę z właścicielem nie ujawnijąc 
  //                             żadnych danych osobowych 
  //             <a  className="find-more" onClick={findMoreHandler}> dowiedz się więcej...</a></h3>
  //             <a  className="find-more" onClick={()=>{
  //               props.setSignUp(true); 
  //               props.setPanelMin(false);
  //               }}>Korzystaj bezpłatnie</a>       

  //             <h3 style={{marginTop:'10px'}}>Znalazłeś zwierzaka z naszym kodem? 
  //                 Zostaw swój adres email do kantaktu a my 
  //                 niezwłocznie poinformujemy właściciela</h3>
  //             <button className="btn" style={{marginLeft:'30px'}}
  //              onClick={()=>setFolded(false)}> Zostaw kontakt</button>

  //         </div>
  //     )
  // }

  if (folded) {
    return (
      <div className="finder-panel ">
        <h3 style={{ marginTop: '10px' }}>Znalazłeś zwierzaka z naszym kodem?
        </h3>
        <button className="btn"
          onClick={() => setFolded(false)}> Zostaw kontakt</button>

      </div>
    )
  }


  return (
    <div className="finder-panel finder-panel-max">
      <button className="btn-close btn" onClick={() => setFolded(true)} 
        style={{ backgroundColor: '#fff', height: 45 }}>
        <i className="fa-regular fa-circle-xmark "></i>
      </button>
      <h2>Czyja zguba?</h2>
      <h3>Znalazłeś zwierzaka z naszym kodem?
        Zostaw swój adres email do kantaktu a&nbsp;my
        niezwłocznie poinformujemy właściciela</h3>
      <form onSubmit={sendHandler}>
        <div className="fp-form-box">
          {err.email ? <div className='fp-err-info'><h5 >popraw adres email</h5></div> : ""}

          <i className="fa fa-envelope icon"></i>
          <input className="input-field" type="text" placeholder="Adres email" name="email"
            onChange={onChangeHandler} value={finder.email} />

        </div>
        <div className="fp-form-box" style={{ marginBottom: 10 }}>
          {err.code ? <div className='fp-err-info'><h5 >kod składa się z 5 znaków</h5></div> : ""}
          {/* <div className="input-container"></div> */}
          <i className="fa fa-lock icon"></i>
          <input className="input-field" type="text" placeholder="Kod XXXXX" name="code"
            onChange={onChangeHandler} value={finder.code} />
        </div>

        {err.consent ? <div className="err-info"><h5 >Zaznaczenie tego pola jest
          niezbędne abyśmy mogli przekazać kontakt właścicielowi</h5></div> : ""}
        <div className="input-container input-container-consent">
          <input className="input-field consent-checkbox" type="checkbox" name="consent"
            onChange={checkConsentHandler} checked={finder.consent} />
          <h5 style={{ fontWeight: 200, fontSize: '12px' }}>
            Wyrażam zgodę na przetwarzanie mojego adresu e-mail w celu przekazania go
            właścicielowi zwierzęcia oznaczonego podanym kodem.
            Zgodnie z <a href="pp.pdf"> polityką prywatności </a>
            serwisu internetowego dlapieska.com.
          </h5>
        </div>

        <center><input type="submit" name="register" value="Wyślij"
          className="btn" style={{ marginTop: '1px' }} /></center>

      </form>

    </div>
  )



}

export default FinderPanel;