
import './App.css';
import './page.css';
import {useEffect, useState} from 'react';
import {checkSession, logout} from './Api/userApi';
import UserPanel from './Components/UserPanel';
import MainPage from './pages/MainPage';
import WarningModal from './Components/WarningModal';
import FinderPanel from './Components/FinderPanel';
import Creator from "./Creator/Creator";

function App() {
  const[user, setUser] = useState({id:0, email:"", name:""});
  const [warningContent, setWarningContent] = useState(null);
  const [creator, setCreator] = useState(false);

  const [signUp, setSignUp] = useState(false);
  const [panelMin, setPanelMin] = useState(true);
  
  useEffect(()=>{
    async function getData(){
       const res = await checkSession(null);
       if(res === null){
        return;
       }
       if(res.errorCode===1){
        if(process.env.NODE_ENV === 'development'){
          console.log("user logged in");
        }
        setUser({...res.payload});
       }else if(res.errorCode===3){
        if(process.env.NODE_ENV === 'development'){
          console.log("user not logged in");
        }
        setUser({id:0, email:"", name:"", active:false});
       }else if(res.errorCode===2){
        if(process.env.NODE_ENV === 'development'){
          console.log("chcek session err");
        }
        setUser({id:0, email:"", name:"", active:false});
       }
     }
     getData();
  },[]);


  const logoutHandler =()=>{
    logout();
    setUser({...user, id:0});
  }

  if(creator){
    return (
        <Creator></Creator>
    );
}


  return (
    <div className="App">
      { warningContent==null ? "" : <WarningModal data={warningContent}></WarningModal>}
      <MainPage user={user} setSignUp={setSignUp} setPanelMin={setPanelMin}></MainPage>
      <FinderPanel setWarning={setWarningContent} setSignUp={setSignUp} setPanelMin={setPanelMin}></FinderPanel>
      <UserPanel user={user} setUser={setUser} setWarning={setWarningContent}
        logoutHandler={logoutHandler} signUp={signUp} setSignUp={setSignUp}
        panelMin={panelMin} setPanelMin={setPanelMin}></UserPanel>
        {/* <RegistrationForm></RegistrationForm> */}
        {/* <LoginForm></LoginForm>
        */}
        
    </div>
  );
}

export default App;
