import Cookies from "../Components/Cookies";
import Footer from "../Components/Footer";
import Shop from "../Components/Shop";
import { sendAction } from "../Api/userApi";

const MainPage = ({ user, setSignUp, setPanelMin }) => {


    if (process.env.NODE_ENV === 'development') {
        console.log("Dev mode", process.env);
    }

    const findMoreHandler = () => {
        sendAction({ x: 'more' });
        window.location.href = 'czyjazguba.html';
    }

    const buyPromoHandler = () => {
        sendAction({ x: 'promo' });
        window.location.href = '#shop';
    }

    const openRegHandler = () => {
        sendAction({ x: 'pr_reg' });
        setSignUp(true);
        setPanelMin(false);
        window.scroll(0,0);
    }




    return (
        <div className="page">

            <div className="page-header" >
                <div className="page-header-background">
                    <div className="page-header-content" >
                        <div className="header-logo">
                            <div className="header-logo-image"></div>
                            <div className="header-title">
                                <h1 className="header-title1">Dla Pieska</h1>
                                <h1 className="header-title2">i Kotka</h1>
                            </div>
                        </div>

                        <div className="header-motto">
                            <div>
                                <h1>Czyja Zguba</h1>
                                <h2>Bezpłatny, prosty i skuteczny sposób na bezpieczeństwo pupila</h2>

                                {/* <a className="find-more" onClick={findMoreHandler}> Dowiedz się więcej...</a>
                                </h2>
                                <a className="find-more" style={{ color: '#15f', fontSize: '26px' }}
                                    onClick={() => {
                                        setSignUp(true);
                                        setPanelMin(false);
                                    }}>Zarejestruj się i korzystaj bezpłatnie</a> */}
                            </div>
                            <div className="header-motto-img"></div>

                        </div>
                    </div>
                </div>
                <div className="page-header-content" >



                    {/* <div className="header-promo-shop">
                        <div>
                            <a href="#shop">
                                <div className="header-promo-shop-promo-info">
                                    <span><br />Sprawdź stylowe i zwiększające bezpieczeństwo
                                        Twojego pupila artykuły w naszym sklepie...</span>

                                </div>
                            </a>
                        </div>


                    </div> */}



                </div>
                <div className="page-header-content" >
                    <div className="page-header-background-bottom">

                        <div className=" btn reg-button" 
                        onClick={openRegHandler}>
                            Zarejestruj się i korzystaj bezpłatnie
                        </div>
                        <div className="page-hint" >
                            To nic Cię nie kosztuje,
                            <br />a może okazać się bezcenne.
                        </div>
                    </div>
                </div>
                <div className="page-header-background-bottom2" >

                </div>
            </div>
            {/* <div id='shop'>
                <div className="shop-title">
                    <h1>Sklep dla pieska i kotka</h1>
                    <div style={{ width: '80px', height: '80px' }} className="header-logo-image"></div>
                </div>
                <Shop user={user}></Shop>
            </div> */}
            <div className="page-content">
                <div className="page-article">
                    <div className="page-article-title">
                        <h3>To proste:
                        </h3>
                    </div>
                    <div className="page-steps">
                    <div className="page-step">
                        <div className="page-step-num">1 </div>
                        <div className="page-step-desc">Otrzymujesz kod który łatwo umieścisz
                            na adresówce, obroży, bandanie itp.</div>
                        <div className="page-step-example">
                            <div className="address-example">
                                <h3>Azor</h3>
                                <h4>Kontakt na:<br />DlaPieska.com</h4>
                                <h3>ABC12</h3>
                            </div>
                        </div>
                    </div>
                    <div className="page-step-if" style={{ padding: 20 }}>A jeśli zwierzak zaginie:</div>
                    <div className="page-step">
                        <div className="page-step-num">2 </div>
                        <div className="page-step-desc">Znalazca wpisuje kod na naszej stronie i
                            pozostawia kontakt do siebie</div>
                    </div>
                    <div className="page-step" >
                        <div className="page-step-num">3 </div>
                        <div className="page-step-desc">Kontaktujesz się ze znalazcą i zguba wraca do domu</div>
                    </div>
                    </div>

                    <div className="page-hint" >
                        Znalazca nie musi zabierać zwierzaka
                        do weterynarza, czy nawet dotykać.
                    </div>
                </div>

                <div className="page-article">
                    <div className="page-article-title">
                        <h3>Czy jest mi to potrzebne,
                            <br />przecież ja pilnuję mojego podopiecznego?
                        </h3>
                    </div>
                    <div className="page-article-content">
                        <p>Niestety wypadki się zdarzają.</p>
                        <p>Każdego roku do polskich schronisk trafia nawet 70 tysięcy !!! zwierząt.
                            <br />Czyli średnio 190 zwierząt każdego dnia !!!.
                        </p>
                        <p>Szacuje się że blisko 60% z nich nie trafia do pierwotnego właściciela.</p>
                        <br /><p> Natomiast ponad 90% zagubionych zwierzaków, które zostały
                            wyposażone w systemy identyfikacji, szybko wraca do domu.
                        </p>
                    </div>


                    <div className="page-hint" >
                        Nie pozwól aby Twój przyjaciel
                        stracił dom.
                    </div>
                </div>

                <div className="page-article">
                    <div className="page-article-title">
                        <h3>Mój pies ma czip,
                            <br />czy to nie wystarczy?
                        </h3>
                    </div>
                    <div className="page-article-content">
                        <p>To bardzo dobrze, jednak zwróć uwagę że:</p>
                        <ul className="page-list">
                            <li>Aby odczytać czip, trzeba schwytać zwierzę.
                                Wystraszony psiak może uciec i stracić szansę na pomoc.

                                <br /> <span>Kod Czyja Zguba jest łatwy do odczytania, nawet z pewnej odległości.</span>
                            </li>
                            <li>
                                Następnie trzeba zawieźć zgubę do weterynarza.
                                Nie każdy ma taką możliwość, czas lub po prostu może obawiać
                                się dodtykać obce zwierzę.
                                <br /><span>Czyja Zguba umożliwia natychmiastowe powiadomienie przez naszą
                                    stronę, wystarczy odczytać kod.</span>
                            </li>
                        </ul>
                    </div>


                    <div className="page-hint" >
                        Nie chodzi o to co jest lepsze,
                        warto mieć i czip i nasz kod.
                    </div>
                    <div className=" btn reg-button" style={{ marginTop: 20 }}
                     onClick={openRegHandler}>
                        Zarejestruj się i korzystaj bezpłatnie
                    </div>
                </div>


                <div className="page-article">
                    <div className="page-article-title">
                        <h3>Jak chroniona jest
                            <br />moja prywatność?
                        </h3>
                    </div>
                    <div className="page-article-content">
                        <ul className="page-list">
                            <li>Aby się zarejestrować wystarczy że podasz adres email.</li>
                            <li>Twojego adresu email nie ujawniamy nikomu.</li>
                            <li>Na wyposażeniu zwierzęcia widoczny jest tylko kod, 
                                znajomość kodu nie pozwala na dostęp do Twojego adresu.
                            </li>
                        </ul>
                       

                    </div>


                    <div className="page-hint" >
                        Ważne jest dla nas bezpieczeństwo
                        <br/>Twojego pupila oraz Twoich danych 
                    </div>
                </div>
            </div>
            <Footer></Footer>

            <Cookies></Cookies>

        </div>
    );
}

export default MainPage;