//const url = "http://localhost/dlapieska/gate.php";
const url = "gate.php";

export const login = async (data) => {
  let specUrl = url + "?a=login";
  return send(specUrl, data);
}

export const logout = async () => {
    let specUrl = url + "?a=logout";
    return send(specUrl, null);
  }

export const checkSession = async (data) => {
    let specUrl = url + "?a=check_session";
    return send(specUrl, data);
  }

export const signup = async (data) => {
    let specUrl = url + "?a=signup";
    return send(specUrl, data);
  }

export const sendActivationEmail = async (email) => {
    const data = {email:email};
    let specUrl = url + "?a=send-act-email";
    return send(specUrl, data);
  }

  async function send(specUrl, data){
    if(process.env.NODE_ENV === 'development'){
      console.log("Api send", specUrl, data);
    }
    try {
      const response = await fetch(specUrl, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json"
        },
        credentials:"include",
      })
      
      if(process.env.NODE_ENV === 'development'){
        const resClon = response.clone();
        const txt = await resClon.text();
        console.log("Api rec raw:", txt);  
      }
      const jsonObject = await response.json();
      if(process.env.NODE_ENV === 'development'){
        console.log("Api rec json:", jsonObject);  
      }
      return jsonObject;
    } catch (error) {
      return null;
    }
  }

  export const getMessages = async () => {
    let specUrl = url + "?a=get-mess";
    return send(specUrl, {});
  }

  export const finderSendMessage = async (data) => {
    let specUrl = url + "?a=finder-mess";
    return send(specUrl, data);
  
  }

  export const activate = async (data) => {
    let specUrl = url + "?a=activate";
    return send(specUrl, data);
  
  }

  export const passwordReset = async (data) => {
    let specUrl = url + "?a=pass_rst";
    return send(specUrl, data);
  }

  export const passwordResetVerify = async (data) => {
    let specUrl = url + "?a=pass_rst_v";
    return send(specUrl, data);
  
  }

  export const changePass = async (data) => {
    let specUrl = url + "?a=change-pass";
    return send(specUrl, data);
  
  }


////////////////////////////////////////Shop
export const getProducts = async (data) => {
  let specUrl = url + "?a=get-products";
  return send(specUrl, data);
}


export const putOrder = async (data) => {
  let specUrl = url + "?a=put-order";
  return send(specUrl, data);

}

///////////////////////////
export const sendAction = async (data) => {
  let specUrl = url + "?a=action";
  return send(specUrl, data);

}