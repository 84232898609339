import './creator.css';
import { useState } from 'react';
import AddImageDialog from './AddImageDialog';
import AddTextDialog from './AddTextDialog';

const example = [
    { name: "pies 1", url: 'https://dlapieska.com/imgc/dog1.png', w: 150, h: 100, t: 0, l: 0, r: 0 },
    { name: "kot i pies", url: 'https://dlapieska.com/imgc/dog2.png', w: 250, h: 100, t: 30, l: 150, r: -45 },
    { name: "pies 1", url: 'https://dlapieska.com/imgc/dog1.png', w: 150, h: 100, t: 0, l: 0, r: 0 },
    { name: "pies 1", url: 'https://dlapieska.com/imgc/dog1.png', w: 150, h: 100, t: 0, l: 0, r: 0 },
    { name: "pies 1", url: 'https://dlapieska.com/imgc/dog1.png', w: 150, h: 100, t: 0, l: 0, r: 0 },
];



const Creator = () => {

    const [content, setContent] = useState(example);
    const [sellectedItem, setSellectedItem] = useState(2);
    const [addImg, setAddImg] = useState(false);
    const [addText, setAddText] = useState(false);
    const [prevText, setPrevText] = useState({ txt: "", s: 24, c: '#000', t: 100, l: 50, r: 0, ff: 'Arial' });

    const sellectItemHandler = (i) => {
        setSellectedItem(i);
    }

    const addImageHandler = (data) => {
        const tmpList = [...content];
        //tmpList.splice(0,0,data);
        const newItem = { name: data.name, url: data.url, w: 150, h: 150, t: 75, l: 150, r: 0 };
        tmpList.push(newItem);
        setContent(tmpList);
        setAddImg(false);
        setSellectedItem(content.length);
    }

    const addTextHandler = () => {
        if(prevText.txt.length < 1){
            setAddText(false);
            return;
        }

        const tmpList = [...content];
        //tmpList.splice(0,0,data);
        const newItem = { ...prevText };
        tmpList.push(newItem);
        setContent(tmpList);
        setAddText(false);
        setSellectedItem(content.length);
        setPrevText({ txt: "", s: 24, c: '#000', t: 100, l: 50, r: 0, ff: 'Arial' });
    }

    const design = content.map((item, i) => {

        let rot = "rotate(" + item.r + "deg)";
        let sellClass = "";
        if (sellectedItem === i) {
            sellClass = "sell-prev";
        }

        if (item.txt !== undefined) {
            return (
                <div className={'item ' + sellClass} key={i} style={{
                    fontSize: item.s, fontFamily: item.ff, color:item.c,
                    top: item.t, left: item.l, transform: rot,
                    }}  >
                    {item.txt}
                </div>
            )
        } else {
            let url = "URL('" + item.url + "')";
            return (

                <div className={'item ' + sellClass} key={i} style={{
                    backgroundImage: url, width: item.w, height: item.h,
                    top: item.t, left: item.l, transform: rot,
                }}  >

                </div>
            )
        }
    });

    if (prevText.txt.length > 0) {
        design.push(
            (<div style={{ position: 'absolute', left: 50, top: 100, fontSize: prevText.s, color: prevText.c, fontFamily: prevText.ff }
            }>{prevText.txt}</div>)
        );
    }


    let itemsList = content.map((item, i) => {
        let sellClass = "";
        if (sellectedItem === i) {
            sellClass = "sell";
        }
        if (item.txt !== undefined) {
            return (
                <div className={'items-list-item ' + sellClass} key={i} onClick={() => sellectItemHandler(i)} 
                    style={{height:40}}>
                    {item.txt}
                </div>
            )
        } else {
            let url = "URL('" + item.url + "')";

            return (
                <div className={'items-list-item ' + sellClass} key={i} onClick={() => sellectItemHandler(i)} >
                    {item.name}
                    <div className={'items-list-item-image ' + sellClass} key={i} style={{ backgroundImage: url, }}  ></div>
                </div>
            )
        }
    });
    itemsList = itemsList.reverse();



    const ctrlHandler = (dir) => {
        const tmpItem = content[sellectedItem];
        if (dir === 1) {
            tmpItem.t -= 5;
        } else if (dir === 2) {
            tmpItem.t += 5;
        } else if (dir === 3) {
            tmpItem.l -= 5;
        } else if (dir === 4) {
            tmpItem.l += 5;
        } else if (dir === 5) {
            tmpItem.r -= 5;
        } else if (dir === 6) {
            tmpItem.r += 5;
        } else if (dir === 7) {
            if (tmpItem.txt !== undefined) {
                tmpItem.s -= 2;
            } else {
                tmpItem.w -= 5;
                tmpItem.h -= 5;
            }
        } else if (dir === 8) {
            if (tmpItem.txt !== undefined) {
                tmpItem.s += 2;
            } else {
                tmpItem.w += 5;
                tmpItem.h += 5;
            }
        }


        //console.log(tmpItem);
        const tmpTab = [...content];
        tmpTab[sellectedItem] = tmpItem;
        setContent(tmpTab);
    }

    let controls = (
        <div className='controls-container'>
            <h3>Przesuń</h3>
            <button className='ctrl-btn' style={{ width: '64px' }} onClick={() => ctrlHandler(1)}>up</button>
            <button className='ctrl-btn' onClick={() => ctrlHandler(3)}>l</button>
            <button className='ctrl-btn' onClick={() => ctrlHandler(4)}>r</button>
            <button className='ctrl-btn' style={{ width: '64px' }} onClick={() => ctrlHandler(2)}>dn</button>
        </div>
    )

    let rotationCtrl = (
        <div className='controls-container' style={{ height: '60px', marginTop: 10 }}>
            <h3 >Obróć</h3>
            <button className='ctrl-btn' style={{ width: '54px' }} onClick={() => ctrlHandler(5)}>l</button>
            <button className='ctrl-btn' style={{ width: '54px' }} onClick={() => ctrlHandler(6)}>r</button>
        </div>
    )

    let scaleCtrl = (
        <div className='controls-container' style={{ height: '60px', marginTop: 30 }}>
            <h3 >Powiększ</h3>
            <button className='ctrl-btn' style={{ width: '54px' }} onClick={() => ctrlHandler(7)}>-</button>
            <button className='ctrl-btn' style={{ width: '54px' }} onClick={() => ctrlHandler(8)}>+</button>
        </div>
    )

    const deleteItemHandler = () => {
        const tmpTab = [...content];
        tmpTab.splice(sellectedItem, 1);
        setContent(tmpTab);
    }
    const moveUpHandler = () => {
        const tmpTab = [...content];
        const tmpItem = { ...content[sellectedItem] };
        tmpTab.splice(sellectedItem, 1);
        tmpTab.splice(sellectedItem + 1, 0, tmpItem);
        setContent(tmpTab);
        setSellectedItem(sellectedItem + 1);
    }

    const addImgPanel = (
        <div className='cre-add-img-panel'>
            <button style={{ width: 75 }} className='list-ctrl-btn' onClick={() => setAddImg(false)} >X</button>
        </div>
    );

    const controlsPanel = (
        <>
            <div>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <button style={{ width: 75 }} className='list-ctrl-btn' onClick={() => setAddImg(true)} >Dodaj grafikę</button>
                    <button style={{ width: 75 }} className='list-ctrl-btn' onClick={() => setAddText(true)} >Dodaj tekst</button>

                </div>
                <div className='items-list'>

                    <button className='list-ctrl-btn' onClick={moveUpHandler} >U</button>
                    <button className='list-ctrl-btn' onClick={deleteItemHandler}>D</button>
                    <div>{itemsList}</div>
                </div>
            </div>
            <div>
                {controls}
                {rotationCtrl}
                {scaleCtrl}
            </div>
        </>
    );

    let panelContent = controlsPanel;
    if (addImg) {
        panelContent = (<AddImageDialog setAddImg={setAddImg} addImageHandler={addImageHandler}></AddImageDialog>);
    }
    if (addText) {
        panelContent = (<AddTextDialog setAddText={setAddText} setPrevText={setPrevText} prevText={prevText}
            addTextHandler={addTextHandler}></AddTextDialog>)
    }

    return (
        <div>
            <div className="cre_container">

                <div className="prev">
                    {design}
                </div>
                <div className="p1">
                    {panelContent}
                </div>




            </div>
        </div>
    )
}

export default Creator;