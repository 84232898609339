import { useState } from "react";

const colors = ['#000','#c0c0c0','#808080', '#fff','#800000','#ff00ff',
    '#008000','#80ff00','#808000','#ffff00','#000080','#0000ff','#008080','#00ffff'];
const fonts = ['Arial','Century Gothic','Courier New','Impact','Palatino Linotype',
                'Tahoma', 'Trebuchet MS'];

const AddTextDialog = ({setAddText, setPrevText, prevText, addTextHandler})=>{

    const onChangeHandler = (e)=>{
        setPrevText({...prevText, txt:e.target.value});
    }
    const setColorHandler=(c)=>{
        setPrevText({...prevText, c:c});
    }
    const incSize = ()=>{
        setPrevText({...prevText, s:prevText.s+2});
    } 
    const decSize = ()=>{
        setPrevText({...prevText, s:prevText.s-2});
    } 
    const sellectFontHandler = (f) =>{
        setPrevText({...prevText, ff:f});
    }

    const confirmHandler = () =>{
        addTextHandler();
    }

    const colorPicker = colors.map((c,k)=>{
        return (
            <div key={k} style={{width:40, height:40, backgroundColor:c}}
                onClick={()=>setColorHandler(c)}></div>
        )
    })

    // const fontSellector = fonts.map(((item, i) => {
    //     return <option key={i} value={item}>
    //         <span style={{fontStyle:item}}>{item}</span>
    //     </option>
    // }))
    const fontSellector = fonts.map(((item, i) => {
        return <div key={i} onClick={()=>sellectFontHandler(item)}>
            <span style={{fontFamily:item}}>{item}</span>
        </div>
    }))

    return (
        <div className='cre-add-img-panel'>
            
            <button style={{ width: 75 }} className='list-ctrl-btn' onClick={() => setAddText(false)} >X</button>
            <div className="cre-add-list">
               <h3>Napisz tekst do 15 zanków</h3>
               <input className="input-field" type="text" placeholder="Text" name="name"
                  onChange={onChangeHandler} value={prevText.txt} maxLength={15}/>
                <h3>Wybierz kolor</h3>
                <div style={{display:'flex', flexWrap:'wrap'}}>{colorPicker}</div>
                <h3>Wybierz rozmiar</h3>
                <button style={{ width: 75 }} className='list-ctrl-btn' onClick={decSize} >-</button>
                <button style={{ width: 75 }} className='list-ctrl-btn' onClick={incSize} >+</button>
                <h3>Wybierz styl</h3>
                <div >
                    {fontSellector}
                </div>
                <button style={{ width: 75 }} className='list-ctrl-btn' onClick={confirmHandler} >Potwierdź</button>
            </div>
        </div>
    )
}

export default AddTextDialog;