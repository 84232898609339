const avImgList = [
    { name: "pies 1", url: 'https://dlapieska.com/imgc/dog1.png'},
    { name: "kot i pies", url: 'https://dlapieska.com/imgc/dog2.png' },
    { name: "kość 1", url: 'https://dlapieska.com/imgc/bone1.png' },
    { name: "kość 2", url: 'https://dlapieska.com/imgc/bone3.png' },
    { name: "pies x", url: 'https://dlapieska.com/imgc/dog1.png'},
    { name: "kość 2", url: 'https://dlapieska.com/imgc/bone3.png' },
    { name: "pies x", url: 'https://dlapieska.com/imgc/dog1.png'},
];

const AddImageDialog = ({setAddImg, addImageHandler}) => {

    let itemsList = avImgList.map((item, i) => {
        let url = "URL('" + item.url + "')";
       
        return (
            <div className='items-list-item ' key={i}  >
                <div className='items-list-item-image '  key={i} style={{ backgroundImage: url, }}  
                    onClick={()=>addImageHandler(item)}></div>
            </div>
        )
    });

    return (
        <div className='cre-add-img-panel'>
            <button style={{ width: 75 }} className='list-ctrl-btn' onClick={() => setAddImg(false)} >X</button>
            <div className="cre-add-list">
                {itemsList}
            </div>
        </div>
    )
}

export default AddImageDialog;